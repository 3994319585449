(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('LoadingTimeoutService', LoadingTimeoutService);

    LoadingTimeoutService.$inject = ['$timeout', 'ModalService', '$state', 'LogoutService'];

    function LoadingTimeoutService($timeout, ModalService, $state, LogoutService) {

        var service = {
            setLoadingTimeout: setLoadingTimeout,
            cancelLoadingTimeout: cancelLoadingTimeout,
            onConditionChange: onConditionChange
        };

        return service;

        function setLoadingTimeout(secondaryAction) {
            service.loadingTimeout = $timeout(function() {
                var config = {
                    title: 'global.attention',
                    hasCheckIcon: false,
                    bodyText: 'global.error.longProcessing',
                    primaryActionLabel: 'global.components.loadingOverlay.cancel',
                    primaryActionValue: 'cancel',
                    secondaryActionLabel: 'global.components.loadingOverlay.' + secondaryAction,
                    secondaryActionValue: secondaryAction
                };
                var resolveActionsFnc = function(action) {
                    /* eslint-disable indent */
                    switch (action) {
                        case 'logout':
                            LogoutService.logout();
                            break;
                        case 'continue':
                            service.setLoadingTimeout(secondaryAction);
                            break;
                    }
                    /* eslint-enable indent */
                };
                service.modalInstance = ModalService.showSaveResultModal(config, resolveActionsFnc);
            }, 120000);
        }

        function cancelLoadingTimeout() {
            $timeout.cancel(service.loadingTimeout);
        }

        function onConditionChange(condition, secondaryAction) {
            if (condition) {
                service.cancelLoadingTimeout();
                service.setLoadingTimeout(secondaryAction);
            } else {
                if (service.modalInstance) {
                    service.modalInstance.close();
                }
                service.cancelLoadingTimeout();
            }

        }
    }
})();


(function() {
    'use strict';

    angular
        .module('cockpit2Login')
        .factory('AuthProvider', AuthProvider);

    AuthProvider.$inject = ['$http'];

    function AuthProvider ($http) {
        var service = {
            login: login,
            logout: logout
        };

        return service;

        function login (credentials) {
            var data = {
                username: credentials.username,
                password: credentials.password,
                rememberMe: credentials.rememberMe
            };
            if (credentials.pin) {
                data.pin = credentials.pin;
            }
            log('About to POST api/authenticate');
            return $http.post('api/authenticate', data);
        }

        function logout () {
            return $http.post('api/logout');
        }
    }
})();

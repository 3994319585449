(function () {
    'use strict';
    angular
        .module('cockpit2Shared')
        .component('appVersionNotification', {
            templateUrl: 'shared/directives/appVersionNotification/app-version-notification.html',
            controller: appVersionNotificationCtrl
        });

    appVersionNotificationCtrl.$inject = ['$scope', '$window', 'VersionService'];

    function appVersionNotificationCtrl($scope, $window, VersionService) {

        var vm = this;
        vm.reload = reload;
        vm.close = close;
        vm.versionMismatch = false;
        vm.closed = false;

        function reload() {
            $window.location.reload();
        }

        function close() {
            vm.closed = true;
        }

        var unwatch =
            $scope.$watch(function () {
                return VersionService.versionMismatch;
            },
            function (newValue) {
                if (!vm.versionMismatch && newValue) {
                    vm.versionMismatch = true;
                }
            });

        $scope.$on('$destroy', function () {
            unwatch();
        });
    }
})();

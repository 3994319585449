(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('Client', Client);

    Client.$inject = ['$http', '$q'];

    function Client($http, $q) {

        var deferred = {};

        var service = {
            getSla: getSla,
            getClients: getClients,
            getCurrentClientApiUrl: getCurrentClientApiUrl,
            getCurrentPartner: getCurrentPartner,
            getCurrentPartnerId: getCurrentPartnerId,
            getFlatClientStructureForCurrentClient: getFlatClientStructureForCurrentClient,
            getFlatPartnerStructureForCurrentPartner: getFlatPartnerStructureForCurrentPartner,
            findClient: findClient,
            findPartner: findPartner,
            getFlatClientStructureForClient: getFlatClientStructureForClient,
            getFlatClientStructureForPartner: getFlatClientStructureForPartner,
            getFlatPartnerStructure: getFlatPartnerStructure,
            doesClientTreeContainClient: doesClientTreeContainClient,
            getDirectPartnerOfClient: getDirectPartnerOfClient,
            doesCurrentClientHaveAnySubclients: doesCurrentClientHaveAnySubclients,
            hasTechAccount: hasTechAccount,
            hasTechAccountGroup: hasTechAccountGroup,
            getPartners: getPartners,
            init: init,
            wasInitialized: false,
            currentClient: {
                id: '',
                contractualPartnerId: '',
                description: '',
                type: ''
            },
            structure: {},
            blockSwitcher: false
        };

        service.init();

        return service;

        function getCurrentClientApiUrl() {
            if (service.currentClient.type === 'client') {
                return 'api/clients/' + service.currentClient.id;
            } else {
                return 'api/contractualPartners/' + service.currentClient.contractualPartnerId;
            }
        }

        function getSla() {
            if (service.currentClient.type === 'client') {
                return $http.get('api/clients/' + service.currentClient.id + '/sla');
            } else {
                return $http.get('api/contractualPartners/' + service.currentClient.id + '/sla');
            }
        }

        function getClients() {
            return deferred.promise;
        }

        function init() {
            deferred = $q.defer();
            log('About to GET api/clients');
            return $http.get('api/clients').then(function (response) {
                log('After GET api/clients');
                if ((!response.data.partners || response.data.partners.length === 0) &&
                    (response.data.clients && response.data.clients.length === 1) &&
                    (!response.data.clients[0].clients || response.data.clients[0].clients.length === 0)) {
                    service.currentClient.id = response.data.clients[0].clientId;
                    service.currentClient.description = response.data.clients[0].description;
                    service.currentClient.type = 'client';
                    service.blockSwitcher = true;
                } else {
                    service.currentClient.id = response.data.erpId;
                    service.currentClient.contractualPartnerId = response.data.contractualPartnerId;
                    service.currentClient.description = response.data.description;
                    service.currentClient.type = 'partner';
                    service.blockSwitcher = false;
                }
                service.currentClient.hasTechAccount = response.data.hasTechAccount;
                service.structure = response.data;
                service.wasInitialized = true;
                deferred.resolve(service.structure);
            });
        }

        function getCurrentPartner() {
            if (service.currentClient.type === 'partner') {
                return findPartner(service.structure, service.currentClient.id);
            } else {
                return getDirectPartnerOfClient(service.currentClient.id, service.structure);
            }
        }

        function getCurrentPartnerId() {
            if (service.currentClient.type === 'partner') {
                return service.currentClient.contractualPartnerId;
            } else {
                var partner = getDirectPartnerOfClient(service.currentClient.id, service.structure);
                if (partner) {
                    return partner.contractualPartnerId;
                }
            }
        }

        function getDirectPartnerOfClient(clientId, currentPartner) {
            if (currentPartner.clients && currentPartner.clients.length > 0) {
                for (var i = 0; i < currentPartner.clients.length; i++) {
                    if (doesClientTreeContainClient(clientId, currentPartner.clients[i])) {
                        return currentPartner;
                    }
                }
            }
            if (currentPartner.partners && currentPartner.partners.length > 0) {
                for (var j = 0; j < currentPartner.partners.length; j++) {
                    var partner = getDirectPartnerOfClient(clientId, currentPartner.partners[j]);
                    if (partner) {
                        return partner;
                    }
                }
            }
        }

        function doesClientTreeContainClient(clientId, client) {
            if (client.clientId === clientId) {
                return true;
            } else if (client.clients && client.clients.length > 0) {
                for (var k = 0; k < client.clients.length; k++) {
                    if (doesClientTreeContainClient(clientId, client.clients[k])) {
                        return true;
                    }
                }
            }
        }

        function getFlatClientStructureForCurrentClient() {
            if (service.currentClient.type === 'partner') {
                var partner = findPartner(service.structure, service.currentClient.id);
                return getFlatClientStructureForPartner(partner);
            } else {
                var client = findClient(service.structure, service.currentClient.id);
                return getFlatClientStructureForClient(client);
            }
        }

        function getFlatClientStructureForPartner(partner) {
            var result = [];
            if (partner.clients && partner.clients.length > 0) {
                for (var i = 0; i < partner.clients.length; i++) {
                    result = result.concat(buildFlatClientStructure(partner.clients[i]));
                }
            }
            if (partner.partners && partner.partners.length > 0) {
                for (var j = 0; j < partner.partners.length; j++) {
                    result = result.concat(getFlatClientStructureForPartner(partner.partners[j]));
                }
            }
            return result;
        }

        function getFlatClientStructureForClient(client) {
            var result = [];
            result.push({
                clientId: client.clientId,
                description: client.description,
                hasTechAccount: client.hasTechAccount,
                techAccountGroups: client.techAccountGroups
            });
            if (client.clients && client.clients.length > 0) {
                for (var k = 0; k < client.clients.length; k++) {
                    result = result.concat(buildFlatClientStructure(client.clients[k]));
                }
            }
            return result;
        }

        function buildFlatClientStructure(client) {
            var result = [];
            result.push({
                clientId: client.clientId,
                description: client.description,
                hasTechAccount: client.hasTechAccount,
                techAccountGroups: client.techAccountGroups
            });
            if (client.clients && client.clients.length > 0) {
                for (var k = 0; k < client.clients.length; k++) {
                    result = result.concat(buildFlatClientStructure(client.clients[k]));
                }
            }
            return result;
        }

        function findPartner(partner, partnerId, shouldUseContractualPartnerId) {
            var isMatch = shouldUseContractualPartnerId ? partner.contractualPartnerId === partnerId : partner.erpId === partnerId;
            if (isMatch) {
                return partner;
            } else if (partner.partners && partner.partners.length > 0) {
                for (var i = 0; i < partner.partners.length; i++) {
                    var tmp = findPartner(partner.partners[i], partnerId , shouldUseContractualPartnerId);
                    if (tmp) {
                        return tmp;
                    }
                }
            }
        }

        function findClient(structure, clientId) {
            clientId = parseInt(clientId);
            if (parseInt(structure.clientId) === clientId) {
                return structure;
            }
            if (structure.partners && structure.partners.length > 0) {
                for (var i = 0; i < structure.partners.length; i++) {
                    var tmp = findClient(structure.partners[i], clientId);
                    if (tmp) {
                        return tmp;
                    }
                }
            }
            if (structure.clients && structure.clients.length > 0) {
                for (var j = 0; j < structure.clients.length; j++) {
                    var tmp2 = findClient(structure.clients[j], clientId);
                    if (tmp2) {
                        return tmp2;
                    }
                }
            }
        }

        function doesCurrentClientHaveAnySubclients() {
            var clientList = getFlatClientStructureForCurrentClient();
            return clientList.length > 1;
        }

        function getFlatPartnerStructureForCurrentPartner() {
            if (service.currentClient.type === 'partner') {
                var partner = findPartner(service.structure, service.currentClient.id);
                return getFlatPartnerStructure(partner);
            }
        }

        function getFlatPartnerStructure(partner) {
            var result = [];
            result.push({
                contractualPartnerId: partner.contractualPartnerId,
                erpId: partner.erpId,
                description: partner.description,
                hasTechAccount: partner.hasTechAccount
            });
            if (partner.partners && partner.partners.length > 0) {
                for (var k = 0; k < partner.partners.length; k++) {
                    result = result.concat(getFlatPartnerStructure(partner.partners[k]));
                }
            }
            return result;
        }

        function getClientOrPartnerStructure(type, id) {
            if (type === 'client') {
                return findClient(service.structure, id);
            } else if (type === 'partner') {
                return findPartner(service.structure, id);
            }
        }

        function hasTechAccount(type, id, deepSearch) {
            return searchForPropertyInStructure('hasTechAccount', null, cb, deepSearch, type, id);

            function cb(propValue) {
                return propValue === true;
            }
        }

        function hasTechAccountGroup(type, id, groupName, deepSearch) {
            return searchForPropertyInStructure('techAccountGroups', null, checkForTechAccountGroup, deepSearch, type, id);

            function checkForTechAccountGroup(groupList) {
                if (_.isArray(groupName)) {
                    return _.intersection(groupName, groupList).length > 0;
                } else {
                    return _.find(groupList, function (el) {
                        return el === groupName;
                    });
                }
            }
        }

        function getPartners(partnerId) {
            var partner = findPartner(service.structure, partnerId, true);
            return getFlatPartnerStructure(partner);
        }

        function searchForPropertyInStructure(propName, clientOrPartnerStructure, checkCallback, deepSearch, clientOrPartner, clientOrPartnerId) {
            clientOrPartnerStructure = clientOrPartnerStructure ? clientOrPartnerStructure :
                                                                    getClientOrPartnerStructure(clientOrPartner, clientOrPartnerId);
            if (clientOrPartnerStructure[propName] && checkCallback(clientOrPartnerStructure[propName])) {
                return true;
            } else if (deepSearch) {
                if (clientOrPartnerStructure && clientOrPartnerStructure.clients && clientOrPartnerStructure.clients.length > 0) {
                    for (var k = 0; k < clientOrPartnerStructure.clients.length; k++) {
                        if (searchForPropertyInStructure(propName, clientOrPartnerStructure.clients[k], checkCallback, deepSearch,
                                'client', clientOrPartnerStructure.clients[k].clientId)) {
                            return true;
                        }
                    }
                }
                if (clientOrPartnerStructure && clientOrPartnerStructure.partners && clientOrPartnerStructure.partners.length > 0) {
                    for (var m = 0; m < clientOrPartnerStructure.partners.length; m++) {
                        if (searchForPropertyInStructure(propName, clientOrPartnerStructure.partners[m], checkCallback, deepSearch,
                                'partner', clientOrPartnerStructure.partners[m].erpId)) {
                            return true;
                        }
                    }
                }
            }
        }

    }
})();

function log(text) {
    var d = new Date();
    var h = d.getHours();
    h = h < 10 ? '0' + h : h;
    var m = d.getMinutes();
    m = m < 10 ? '0' + m : m;
    var s = d.getSeconds();
    s = s < 10 ? '0' + s : s;
    var mil = d.getMilliseconds();
    mil = mil < 10 ? '00' + mil : mil < 100 ? '0' + mil : mil;
    mil = text;
    // console.log(h + ':' + m + ':' + s + ',' + mil + ' ' + text);
}

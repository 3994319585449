(function() {
    angular.module('cockpit2Login')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$q', 'AuthProvider'];

    function LoginService($q, AuthProvider) {
        var service = {
            login: login,
            logout: logout
        };

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    service.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen(data) {
                deferred.resolve(data);
                return cb();
            }

            return deferred.promise;
        }

        function logout() {
            AuthProvider.logout();
        }

        return service;
    }
})();

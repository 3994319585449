(function() {
    'use strict';

    angular
        .module('cockpit2Shared')
        .controller('UnsavedChangesModalController', UnsavedChangesModalController);

    UnsavedChangesModalController.$inject = ['$uibModalInstance', 'config'];

    function UnsavedChangesModalController ($uibModalInstance, config) {

        var vm = this;
        vm.closeModal = closeModal;
        vm.proceed = proceed;
        vm.config = config;

        function closeModal() {
            $uibModalInstance.close(false);
        }

        function proceed() {
            $uibModalInstance.close(true);
        }

    }
})();

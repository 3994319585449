(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('LoginLanguageService', Dictionary);

    Dictionary.$inject = ['$window', '$cookies', '$http', 'DomainCustomizationService'];

    function Dictionary($window, $cookies, $http, DomainCustomizationService) {
        var service = {
            language: 'en',
            getSupportedLanguages: getSupportedLanguages,
            determineLanguage: determineLanguage,
            freezeLanguageSelection: freezeLanguageSelection,
            supportedLanguages: []
        };

        return service;

        function getSupportedLanguages() {
            return $http.get('api/dictionaries/languages').then(function (response) {
                service.supportedLanguages = response.data;
                return service.supportedLanguages;
            });
        }

        function determineLanguage() {
            return DomainCustomizationService.getSettings().then(doDetermineAndSetLanguage);
        }

        function doDetermineAndSetLanguage() {
            var language = doDetermineLanguage();
            service.language = language;
            return language;
        }

        function doDetermineLanguage() {
            if ($cookies.get('cockpit-language')) {
                return $cookies.get('cockpit-language');
            } else {
                var lang = ($window.navigator.language || $window.navigator.userLanguage).split('-')[0];
                var supportedBrowserLocale = _.find(service.supportedLanguages, function (language) {
                    return language.languageId === lang;
                });
                if (supportedBrowserLocale) {
                    return supportedBrowserLocale.languageId;
                } else {
                    return DomainCustomizationService.settings.loginPageLanguageLocaleId.split('_')[0];
                }
            }
        }

        function freezeLanguageSelection(language) {
            $cookies.put('cockpit-language', language);
            service.language = language;
        }
    }
})();


(function() {
    'use strict';

    angular
        .module('cockpit2Login')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password-reset', {
            parent: 'app',
            url: '/passwordReset?token',
            data: {
                authorities: []
            },
            onEnter: function($state, $stateParams) {
                if ($stateParams.token === undefined) {
                    $state.go('login.main');
                }
            },
            views: {
                'content@': {
                    templateUrl: 'loginApp/passwordReset/password-reset.html',
                    controller: 'PasswordResetController',
                    controllerAs: 'PassCtrl'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader',
                                            function ($translate,$translatePartialLoader) {
                                                $translatePartialLoader.addPart('global');
                                                $translatePartialLoader.addPart('password-reset');
                                                return $translate.refresh();
                                            }
                ]
            }
        });
    }
})();

(function() {
    angular
        .module('cockpit2Login')
        .config(locationConfig);

    locationConfig.$inject = ['$locationProvider'];

    function locationConfig($locationProvider) {
        $locationProvider.hashPrefix('');
    }
})();

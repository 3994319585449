(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .controller('SmsModalController', SmsModalController);

    SmsModalController.$inject = ['$uibModalInstance'];

    function SmsModalController($uibModalInstance) {
        var vm = this;

        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('Account', Account);

    Account.$inject = ['$resource', '$rootScope'];

    function Account ($resource, $rootScope) {
        var service = $resource('api/account', {}, {
            'get': {method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        $rootScope.username = response.data.login;
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();

(function() {
    'use strict';

    angular
        .module('cockpit2Shared')
        .config(translationConfig);

    translationConfig.$inject = ['$translateProvider'];

    function translationConfig($translateProvider) {
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: '/cockpit/api/cockpitLabels/{lang}/{part}'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useStorage('translationStorageProvider');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
        $translateProvider.fallbackLanguage('en');
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .controller('ImprintController', ImprintController);

    ImprintController.$inject = ['imprintData'];

    function ImprintController(imprintData) {
        var vm = this;

        vm.imprintData = imprintData;
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('PermissionService', PermissionService);

    PermissionService.$inject = ['$http', '$q', 'Client'];

    function PermissionService($http, $q, Client) {

        var service = {
            clientPermissions: {},
            userPermissions: [],
            initialize: initialize,
            hasGlobalPermission: hasGlobalPermission,
            hasUserPermission: hasUserPermission,
            hasMenuPermission: hasMenuPermission,
            hasClientPermission: hasClientPermission,
            getFlatClientStructureForPartnerWithGivenPermission: getFlatClientStructureForPartnerWithGivenPermission,
            getFlatClientStructureForCurrentClientWithGivenPermission: getFlatClientStructureForCurrentClientWithGivenPermission,
            getClientsWithPermission: getClientsWithPermission,
            init: init
        };

        var serviceDeferred;

        service.init();

        return service;

        function initialize() {
            return serviceDeferred.promise;
        }

        function init() {
            serviceDeferred = $q.defer();
            return Client.getClients().then(function () {
                return $http.get('api/roles').then(function (response) {
                    service.clientPermissions = response.data.clientRoles;
                    service.userPermissions = response.data.otherRoles;
                    serviceDeferred.resolve();
                });
            });
        }

        function hasGlobalPermission(permission, params) {
            var containPartnerId = containParam(params, 'contractualPartnerId'),
                containClientId = containParam(params, 'clientId');
            if ((angular.isUndefined(params) || (!containClientId && containPartnerId) || (!containClientId && !containPartnerId)) &&
                Client.currentClient.type === 'partner') {

                var partner = containPartnerId ? Client.findPartner(Client.structure, params.contractualPartnerId, true) :
                    findPartner(Client.structure, Client.currentClient.id);

                if (partner) {
                    return hasPartnerPermission(partner, permission);
                }

            } else {
                return _.find(service.clientPermissions[containClientId ? params.clientId : Client.currentClient.id.toString()], function (perm) {
                    return permission === perm;
                });
            }

            function containParam(params, param) {
                return angular.isDefined(params) && angular.isDefined(params[param]);
            }

            function findPartner(partner, partnerId) {
                if (partner.erpId === partnerId) {
                    return partner;
                } else if (partner.partners && partner.partners.length > 0) {
                    for (var i = 0; i < partner.partners.length; i++) {
                        var tmp = findPartner(partner.partners[i], partnerId);
                        if (tmp) {
                            return tmp;
                        }
                    }
                }
            }

            function traverseClientForPermission(client, permission) {
                var searchResult = _.find(service.clientPermissions[client.clientId], function (perm) {
                    return perm === permission;
                });
                if (searchResult) {
                    return true;
                } else if (client.clients && client.clients.length > 0) {
                    for (var k = 0; k < client.clients.length; k++) {
                        if (traverseClientForPermission(client.clients[k], permission)) {
                            return true;
                        }
                    }
                }
            }

            function hasPartnerPermission(partner, permission) {
                if (partner.clients && partner.clients.length > 0) {
                    for (var i = 0; i < partner.clients.length; i++) {
                        if (traverseClientForPermission(partner.clients[i], permission)) {
                            return true;
                        }
                    }
                }
                if (partner.partners && partner.partners.length > 0) {
                    for (var j = 0; j < partner.partners.length; j++) {
                        if (hasPartnerPermission(partner.partners[j], permission)) {
                            return true;
                        }
                    }
                }
            }
        }

        function hasUserPermission(permission) {
            var result = _.find(service.userPermissions, function (perm) {
                return perm === permission;
            });
            if (result) {
                return true;
            }
        }

        function hasClientPermission(clientId, permission) {

            var searchResult = _.find(service.clientPermissions[clientId], function (perm) {
                return perm === permission;
            });
            return (searchResult) ? true : false;
        }

        function getClientsWithPermission(partnerId, clientId, permissionName) {
            if (clientId !== undefined && clientId !== null) {
                var client = Client.findClient(Client.structure, clientId);
                return _.filter(Client.getFlatClientStructureForClient(client), function (client) {
                    return hasClientPermission(client.clientId, permissionName);
                });
            }
            var partner = Client.findPartner(Client.structure, partnerId, true);
            return _.filter(Client.getFlatClientStructureForPartner(partner), function (client) {
                return hasClientPermission(client.clientId, permissionName);
            });
        }

        function getFlatClientStructureForPartnerWithGivenPermission(partner, permissionName, withTechAccountOnly) {
            return _.filter(Client.getFlatClientStructureForPartner(partner), function (client) {
                return hasClientPermission(client.clientId, permissionName) && (!withTechAccountOnly || client.hasTechAccount);
            });
        }

        function getFlatClientStructureForCurrentClientWithGivenPermission(permissionName, withTechAccountOnly) {
            var clientList = Client.getFlatClientStructureForCurrentClient();
            var result = [];
            clientList.forEach(function (client) {
                if (hasClientPermission(client.clientId, permissionName)) {
                    if (withTechAccountOnly && client.hasTechAccount || !withTechAccountOnly) {
                        result.push(client);
                    }
                }
            });
            return result;
        }

        function hasMenuPermission(clientId, attrs, evalFn, contractualPartnerId) {
            var result, params = undefined;
            if (clientId || contractualPartnerId) {
                params = {contractualPartnerId: contractualPartnerId, clientId: clientId};
            }
            // If partner selected, check only in other roles. Otherwise, search in client permissions
            if (attrs.strictGlobal === 'true') {
                if (Client.currentClient.type === 'partner') {
                    result = hasUserPermission(attrs.hasPermission);
                } else {
                    result = hasGlobalPermission(attrs.hasPermission, params);
                }
            }
            // Search in client permissions, both for partner and client
            if (attrs.global === 'true') {
                result = hasGlobalPermission(attrs.hasPermission, params);
            } else if (clientId) {
                result = hasClientPermission(clientId, attrs.hasPermission);
            }

            if (!result && attrs.userPermission === 'true') {
                result = hasUserPermission(attrs.hasPermission);
            }

            if (result && (attrs.requireTechAccount)) {
                var techAccounts = evalFn(attrs.requireTechAccount);
                techAccounts = techAccounts === undefined ? attrs.requireTechAccount : techAccounts;

                var deepSearch = !!attrs.deep;
                if (clientId) {
                    result = Client.hasTechAccountGroup('client', clientId, techAccounts, deepSearch);
                } else {
                    result = Client.hasTechAccountGroup(Client.currentClient.type, Client.currentClient.id, techAccounts, deepSearch);
                }
            }

            result = attrs.isReversed === 'true' ? !result : result;

            return result;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('LogoutService', LogoutService);

    LogoutService.$inject = ['Principal', '$http', '$rootScope', '$sessionStorage', '$translatePartialLoader', '$window'];

    function LogoutService(Principal, $http, $rootScope, $sessionStorage, $translatePartialLoader, $window) {

        var service = {
            logout: logout
        };

        return service;

        function performLogoutCall() {
            return $http.post('api/logout').then(function(response) {
                if (!$rootScope.redirected) {
                    $rootScope.previousStateName = undefined;
                    $rootScope.previousStateNameParams = undefined;
                }

                $sessionStorage.loggedUserSettings = {};
                removeTranslations();
                $window.location.replace(response.headers('RedirectUrl'));
            });
        }

        function logout() {
            Principal.authenticate(null);
            performLogoutCall();

        }

        function removeTranslations() {
            _.each($translatePartialLoader.getRegisteredParts(), function (part) {
                $translatePartialLoader.deletePart(part, true);
            });
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .directive('xuraLinkButton', xuraLinkButton);

    function xuraLinkButton() {
        return {
            restrict: 'E',
            templateUrl: 'shared/directives/button/xura-link-button.html',
            replace: true,
            scope: {
                link: '=',
                text: '='
            },
            compile: function (tElement, tAttrs) {
                if (tAttrs.icon) {
                    tElement.find('i').addClass('fa-' + tAttrs.icon);
                    tElement.removeAttr('icon');
                } else {
                    tElement.find('i').remove();
                }
            }
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function stateConfig($stateProvider, $urlRouterProvider) {
        $stateProvider.state('app', {
            abstract: true,
            onEnter: ['$http', '$window', '$log',
                function ($http, $window, $log) {
                    log('About to GET api/authenticate');
                    $http({
                        method: 'GET',
                        url: 'api/authenticate',
                        transformResponse: function (data) {
                            return data;
                        }
                    }).then(function (response) {
                        if (response.data) {
                            var currentHref = $window.location.href;
                            $window.location.replace(currentHref.substring(0, currentHref.lastIndexOf('login')));
                        }
                    }, function (error) {
                        $log.error(error);
                    });
                }
            ]
        });

        $stateProvider.state('login', {
            abstract: true,
            parent: 'app',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'loginApp/login-layout.html',
                    controller: 'LoginController',
                    controllerAs: 'LoginCtrl'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }
                ],
                maintenanceSettings: ['MaintenanceService', function (MaintenanceService) {
                    return MaintenanceService.getMaintenanceSettings().then(function (result) {
                        return result.data;
                    });
                }]
            }
        }).state('login.main', {
            templateUrl: 'loginApp/login-form.html',
            url: '/'
        }).state('login.reset', {
            templateUrl: 'loginApp/forgot-password-form.html',
            url: '/reset'
        }).state('login.imprint', {
            templateUrl: 'loginApp/imprint.html',
            url: '/imprint',
            controller: 'ImprintController',
            controllerAs: 'vm',
            resolve: {
                imprintData: function (ImprintService) {
                    return ImprintService.getImprintFile();
                }
            }
        });

        $urlRouterProvider.otherwise('/');
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('VersionService', VersionService);

    function VersionService() {

        var service = {
            version: null,
            versionMismatch: false,
            checkIfVersionMismatch: checkIfVersionMismatch
        };

        function checkIfVersionMismatch(versionMismatch) {
            if (!service.versionMismatch) {
                service.versionMismatch = versionMismatch === 'true';
            }
        }

        return service;

    }
})();


(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('MaintenanceService', MaintenanceService);

    MaintenanceService.$inject = ['$http'];

    function MaintenanceService($http) {
        var service = {
            getMaintenanceSettings: getMaintenanceSettings
        };

        return service;

        function getMaintenanceSettings() {
            return $http.get('api/maintenance');
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('DomainCustomizationService', DomainCustomizationService);

    DomainCustomizationService.$inject = ['$http'];

    function DomainCustomizationService($http) {

        var service = {
            getSettings: getSettings,
            settings: {
                loginPageMainColor: null,
                loginPageSecondaryColor: null,
                loginPageLanguageLocaleId: null,
                loginPageFont: null,
                loginPageBackgroundColor: null
            }
        };

        return service;

        function getSettings() {
            return $http.get('api/style/visualStyle').then(function(response) {
                service.settings = response.data;
                applyStyles();
            });
        }

        function applyStyles() {

            if (angular.element('#domain-visual-customization')) {
                angular.element('#domain-visual-customization').remove();
            }

            angular.element('head').append(
                '<style id="domain-visual-customization">' +
                'div.domain-main-font *:not(.fa):not(i):not(.glyphicon) {font-family: ' + service.settings.loginPageFont + ' !important}' +
                '.domain-background-primary {background-color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-background-secondary{background-color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.domain-hover-background-primary:hover {background-color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-hover-background-secondary:hover {background-color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.domain-color-primary {color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-color-secondary {color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.domain-hover-color-primary:hover {color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-hover-color-secondary:hover {color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.domain-border-primary {border-color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-border-secondary {border-color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.domain-hover-border-primary:hover {border-color: ' + service.settings.loginPageMainColor + ' !important}' +
                '.domain-hover-border-secondary:hover {border-color: ' + service.settings.loginPageSecondaryColor + ' !important}' +
                '.login-page-background-color {color: ' + service.settings.loginPageBackgroundColor + ' !important}' +
                '.imprint__content a {color: ' + service.settings.loginPageMainColor + ' !important}' +
                '</style>'
            );
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('ModalService', ModalService);

    ModalService.$inject = ['$uibModal'];

    function ModalService($uibModal) {
        return {
            confirmUnsavedChanges: confirmUnsavedChanges,
            showSaveResultModal: showSaveResultModal,
            modal: modal,
            confirm: confirm,
            notify: notify
        };

        function showSaveResultModal(config, resolveActionsFnc, modalDefinitionOverride) {
            var modalDefinition = {
                animation: true,
                templateUrl: 'shared/modal/save-result-modal/save-result-modal.html',
                controller: 'SaveResultModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        return config;
                    }
                }
            };
            for (var property in modalDefinitionOverride) {
                modalDefinition[property] = modalDefinitionOverride[property];
            }
            var modalInstance = $uibModal.open(modalDefinition);
            if (resolveActionsFnc) {                
                modalInstance.result.then(resolveActionsFnc);
            }
            return modalInstance;
        }
        
        function confirmUnsavedChanges(onSuccessHandler, onCancelHandler, config) {
            $uibModal.open({
                animation: true,
                templateUrl: 'shared/modal/unsaved-changes-modal/unsaved-changes-modal.html',
                controller: 'UnsavedChangesModalController',
                controllerAs: 'vm',
                resolve: {
                    config: function () {
                        return angular.extend({}, {
                            title: 'global.controls.confirm',
                            message: 'global.controls.areYouSure',
                            primaryActionLabel: 'global.controls.continue'
                        }, config);
                    }
                }
            }).result.then(function (shouldClose) {
                if (shouldClose) {
                    onSuccessHandler();
                } else {
                    if (onCancelHandler) {
                        onCancelHandler();
                    }
                }
            });
        }

        function modal(templateUrl, controller, config) {
            $uibModal.open(angular.extend({
                animation: true,
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm'
            }, config || {}));
        }

        function confirm(onProceedHandler, onCancelHandler, message, primaryAction) {
            if (arguments.length === 3) {
                primaryAction = message;
                message = onCancelHandler;
                onCancelHandler = undefined;
            }
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'shared/modal/confirm-modal.html',
                controller: function ($uibModalInstance, config) {
                    var vm = this;

                    vm.message = config.message;
                    vm.primaryAction = config.primaryAction;

                    vm.cancel = function () {
                        $uibModalInstance.close();
                        if (onCancelHandler) {
                            onCancelHandler();
                        }
                    };

                    vm.proceed = function () {
                        $uibModalInstance.close();
                        onProceedHandler();
                    };
                },
                controllerAs: 'vm',
                resolve: {
                    config: {
                        message: message,
                        primaryAction: primaryAction
                    }
                }
            });
        }

        function notify(onProceedHandler, message, buttonLabel, title) {
            $uibModal.open({
                animation: true,
                templateUrl: 'shared/modal/notify-modal.html',
                controller: function ($uibModalInstance, config) {
                    var vm = this;

                    if (_.isArray(message)) {
                        vm.messageList = message;
                    } else {
                        vm.message = config.message;
                    }

                    vm.buttonLabel = config.buttonLabel;
                    vm.title = config.title;

                    vm.proceed = function () {
                        $uibModalInstance.close();
                        if (onProceedHandler) {
                            onProceedHandler();
                        }
                    };
                },
                controllerAs: 'vm',
                resolve: {
                    config: {
                        title: title ? title : 'global.error.errorOccurred',
                        message: message,
                        buttonLabel: buttonLabel
                    }
                }
            });
        }
    }
})();

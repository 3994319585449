(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .controller('SaveResultModalController', SaveResultModalController);

    SaveResultModalController.$inject = ['$uibModalInstance', 'PermissionService', 'config'];

    function SaveResultModalController($uibModalInstance, PermissionService, config) {
        var vm = this;

        vm.closeModal = closeModal;
        vm.getSecondaryActionValue = getSecondaryActionValue;
        vm.getSecondaryActionLabel = getSecondaryActionLabel;
        vm.config = config;

        // Config options:
        // title: string
        // hasCheckIcon: boolean
        // bodyText: string
        // primaryActionLabel: string
        // primaryActionValue: string
        // secondaryActionLabel: string
        // secondaryActionValue: string
        // hasCallSupportButton: boolean

        function closeModal(action) {
            $uibModalInstance.close(action);
        }

        function getSecondaryActionValue() {
            if (vm.config.hasCallSupportButton && PermissionService.hasUserPermission('COCKPIT.SUPPORT.EXECUTE')) {
                return 'callSupport';
            }
            return vm.config.secondaryActionValue;
        }

        function getSecondaryActionLabel() {
            if (vm.config.hasCallSupportButton && PermissionService.hasUserPermission('COCKPIT.SUPPORT.EXECUTE')) {
                return 'global.controls.callSupport';
            }
            return vm.config.secondaryActionLabel;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('cockpit2Login', [
            'cockpit2Shared',
            'ui.router',
            'pascalprecht.translate',
            'ui.bootstrap',
            'ui.select',
            'ngSanitize',
            'ngStorage',
            'validation.match'
        ]);
})();

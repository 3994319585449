(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .directive('xuraSelect', xuraSelect);

    xuraSelect.$inject = ['$templateRequest', '$compile', '$parse', '$timeout', '$filter'];

    function xuraSelect($templateRequest, $compile, $parse, $timeout, $filter) {
        return {
            require: 'ngModel',
            scope: {
                ngModel: '=',
                onSelect: '&?',
                ngChange: '&?',
                allowClear: '<?',
                selectFirst: '<?',
                preSelect: '<?',
                placeholder: '<?',
                loading: '<',
                items: '&',
                ngDisabled: '<'
            },
            compile: function (tElement, tAttrs) {
                var cssClass = tAttrs.class;
                var compiledTemplate;
                var labelElement = tElement.find('xura-item-label');
                var selectedLabelElement = tElement.find('xura-item-selected-label');
                var dropdownLabelElement = tElement.find('xura-item-dropdown-label');

                if (cssClass) {
                    tElement.removeClass(cssClass);
                }
                tElement.addClass('xura-select');

                if (tAttrs.virtualScroll === 'true') {
                    tElement.addClass('xura-select--virtual-scroll');
                }

                tElement.empty();
                var linkQueue = [];
                $templateRequest('shared/directives/select/xura-select.html').then(function (template) {
                    template = angular.element(template);

                    if (cssClass) {
                        template.addClass(cssClass);
                    }

                    if (!_.isEmpty(tAttrs.searchEnabled)) {
                        template.attr('search-enabled', tAttrs.searchEnabled);
                    }

                    if (!_.isEmpty(tAttrs.watchChanges)) {
                        template.attr('watch-changes', tAttrs.watchChanges);
                    }

                    if (tAttrs.loading) {
                        template.find('ui-select-match').attr('xura-select-spinner', 'loading && !ngDisabled');
                    }

                    var choicesElement = template.find('ui-select-choices');
                    if (labelElement.length) {
                        template.find('ui-select-match').html(labelElement.html().replace(/item/g, '$select.selected'));
                        choicesElement.html(labelElement.html());
                    }

                    if (selectedLabelElement.length) {
                        template.find('ui-select-match').html(selectedLabelElement.html().replace(/item/g, '$select.selected'));
                    }

                    if (dropdownLabelElement.length) {
                        choicesElement.html(dropdownLabelElement.html());
                    }

                    if (tAttrs.repeat) {
                        choicesElement.attr('repeat', tAttrs.repeat);
                    } else {
                        choicesElement.attr('repeat', tAttrs.itemId +
                            ' as item in filteredItems track by ' + tAttrs.itemId);
                    }

                    choicesElement.attr('virtual-scroll', tAttrs.virtualScroll);

                    compiledTemplate = $compile(template);

                    // jscs:disable angular/no-private-call
                    /* eslint-disable angular/no-private-call */
                    while (linkQueue.length) {
                        var delayedLink = linkQueue.shift();
                        if (delayedLink.scope.$$destroyed) {
                            continue;
                        }
                        link(delayedLink.scope, delayedLink.iElement, delayedLink.iAttrs);

                    }
                    // jscs:enable angular/no-private-call
                    /* eslint-enable angular/no-private-call */
                    linkQueue = null;
                });

                var link = function (scope, iElement, iAttrs) {
                    compiledTemplate(scope, function (clone) {
                        iElement.html(clone);
                    });
                    scope.allowClear = _.isUndefined(scope.allowClear) ? false : scope.allowClear;
                    scope.onSelectCallback = function ($item, $model, preSelect) {
                        if (!preSelect) {
                            scope.isTooltipOpen = scope.isTextTruncated();
                            if (scope.isTooltipOpen) {
                                scope.tooltipLabel = undefined;
                            }
                        }
                        return !scope.onSelect || scope.onSelect({
                            $item: $item,
                            $model: $model
                        });
                    };
                    scope.onChangeCallback = function () {
                        return !scope.ngChange || scope.ngChange();
                    };

                    var itemIdExpression = $parse(iAttrs.itemId);
                    var labelExpression = $parse(iAttrs.itemLabel);

                    function preSelect(items, allowClear, model, selectFirst, preSelect) {
                        if (preSelect !== false && (_.isUndefined(model) || _.isNull(model) || model === '') && !allowClear &&
                            (selectFirst || _.size(items) === 1)) {
                            scope.ngModel = itemIdExpression({
                                item: _.first(items)
                            });
                            $timeout(function () {
                                scope.onSelectCallback(_.first(items), scope.ngModel, true);
                            });
                            return true;
                        }
                    }

                    scope.$watchCollection('items()', function (newValue) {
                        if (newValue) {
                            scope.searchEnabled = newValue.length > 8;
                            scope.filteredItems = $filter('filter')(newValue, scope.searchTerm);
                        }

                        preSelect(newValue, scope.allowClear, scope.ngModel, scope.selectFirst, scope.preSelect);
                    });

                    scope.$watch('searchTerm', function (newValue) {
                        scope.filteredItems = $filter('filter')(scope.items(), newValue);
                    });

                    scope.$watchGroup(['allowClear', 'ngModel', 'selectFirst', 'preSelect'], function (newValue) {
                        preSelect(scope.items(), newValue[0], newValue[1], newValue[2], newValue[3]);
                    });

                    scope.getLabel = function (item) {
                        return labelExpression({
                            item: item
                        });
                    };

                    scope.$watch('isTooltipOpen', function (newValue) {
                        if (newValue) {
                            scope.tooltipLabel = undefined;
                        }
                    });

                    scope.getTooltipLabel = function (item) {
                        if (scope.tooltipLabel) {
                            return scope.tooltipLabel;
                        }
                        return scope.tooltipLabel = scope.getLabel(item);
                    };

                    scope.isTextTruncated = function () {
                        var textContainer = iElement.find('.select2-chosen:not(:empty)');
                        return textContainer.length === 1 && textContainer[0].scrollWidth > textContainer.innerWidth();
                    };

                    scope.getContext = function () {
                        return scope.$parent;
                    };
                };

                return function (scope, iElement, iAttrs) {
                    if (!compiledTemplate) {
                        linkQueue.push({
                            scope: scope,
                            iElement: iElement,
                            iAttrs: iAttrs
                        });
                    } else {
                        link(scope, iElement, iAttrs);
                    }
                };
            }
        };
    }
})();

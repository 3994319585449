(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .component('loadingOverlay', {
            templateUrl: 'shared/loading/loading-overlay.html',
            controller: LoadingOverlayController,
            bindings: {
                condition: '<',
                secondaryAction: '@'
            }
        });

    LoadingOverlayController.$inject = ['LoadingTimeoutService', '$scope'];

    function LoadingOverlayController(LoadingTimeoutService, $scope) {

        var vm = this;

        $scope.$watch(function () {
            return vm.condition;
        }, function () {
        });
    }
})();

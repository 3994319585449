(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .factory('PasswordService', PasswordService);

    PasswordService.$inject = ['$http', '$q'];

    function PasswordService($http, $q) {

        var service = {
            initReset: initReset,
            resetPassword: resetPassword,
            validateToken: validateToken,
            captchaChallenge: captchaChallenge,
            changePassword: changePassword
        };

        return service;

        function changePassword(params) {
            return $http.put('api/password', params).then(function (response) {
                return response.data;
            }, function (response) {
                return $q.reject(response ? response.data : response);
            });
        }

        function initReset(data) {
            return $http.post('api/password/init-reset', data).then(function (e) {
                return e.data;
            });
        }

        function resetPassword(data) {
            return $http.post('api/password/perform-reset', data).then(function (e) {
                return e.data;
            });
        }

        function validateToken(data) {
            return $http.get('api/password/validate-token', {params: data}).then(function (e) {
                return e.data;
            });
        }

        function captchaChallenge(data) {
            return $http.get('api/password/captcha-challenge', {params: data}).then(function (e) {
                return e.data;
            });
        }
    }
})();

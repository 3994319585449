(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$state',
        'LoginService',
        'PasswordService',
        '$translate',
        'LoginLanguageService',
        'maintenanceSettings',
        '$uibModal',
        '$filter',
        '$window'];

    function LoginController($state,
                             LoginService,
                             PasswordService,
                             $translate,
                             LoginLanguageService,
                             maintenanceSettings,
                             $uibModal,
                             $filter,
                             $window) {
        var vm = this;

        vm.translate = $translate;

        vm.loginData = {
            username: '',
            password: '',
            pinCode: ''
        };
        vm.forgotData = {
            username: '',
            captcha: '',
            challengeId: '',
            challengeAnswer: ''
        };
        vm.resetForm = 'reset';
        vm.languages = [];

        vm.login = login;
        vm.resetPassword = resetPassword;
        vm.onLanguageSelect = onLanguageSelect;
        vm.openPopup = openPopup;
        vm.getSubmitLabel = getSubmitLabel;
        vm.goBackFromPinState = goBackFromPinState;
        vm.showError = showError;
        vm.captchaChallenge = captchaChallenge;
        vm.setupForgotPasswordState = setupForgotPasswordState;
        vm.authenticationError = false;
        vm.resetError = false;
        vm.resetSuccess = false;
        vm.loginErrorCode = '';
        vm.insertPinState = false;

        vm.maintenanceMode = maintenanceSettings.maintenanceMode;
        if (maintenanceSettings.maintenanceMode) {
            $state.go('maintenance');
        }

        LoginLanguageService.getSupportedLanguages().then(function (languages) {
            vm.languages = languages;
            LoginLanguageService.determineLanguage().then(useLanguage);
        });

        vm.captchaChallenge();

        function useLanguage(language) {
            vm.selectedLanguage = language;
            $translate.use(language);
        }

        function onLanguageSelect(item, model) {
            LoginLanguageService.freezeLanguageSelection(model);
            $translate.use(model);
        }

        function login(event) {
            vm.loading = true;
            event.preventDefault();
            var params = {
                username: vm.loginData.username,
                password: vm.loginData.password,
                rememberMe: false
            };
            if (vm.insertPinState) {
                params.pin = vm.loginData.pinCode;
            }
            LoginService.login(params, showError).then(function (response) {
                vm.loading = false;
                vm.authenticationError = false;
                // If we're redirected to login, our
                // previousState is already set in the authExpiredInterceptor. When login succesful go to stored state
                $window.location.replace(response.headers('RedirectUrl'));
            }).catch(function () {
            });
        }

        function showError(err) {
            vm.loading = false;
            if (err && err.status === 302) {
                vm.insertPinState = true;
                vm.authenticationError = false;
            } else if (vm.insertPinState) {
                vm.authenticationError = true;
                vm.loginErrorCode = 'incorrect-pin';
                vm.loginData.pinCode = '';
                vm.insertPinState = false;
            } else if (err) {
                vm.authenticationError = true;
                if (err.data.code) {
                    vm.loginErrorCode = err.data.code;
                }
                else {
                    vm.loginErrorCode = 'incorrect-credentials';
                }
            }
        }

        function resetPassword() {
            vm.forgotData.language = LoginLanguageService.language;
            PasswordService.initReset(vm.forgotData).then(function () {
                vm.resetError = false;
                vm.resetSuccess = true;
            }, function (error) {
                if (!!error.data.globalErrors && error.data.globalErrors.length > 0) {
                    vm.resetErrorCode = 'login.resetFail';
                }
                if (!!error.data.fieldErrors && error.data.fieldErrors.length > 0) {
                    vm.resetErrorCode = error.data.fieldErrors[0].code;
                }
                vm.resetError = true;
                vm.resetSuccess = false;
                vm.forgotData.challengeAnswer = '';
                captchaChallenge();
            });
        }

        function captchaChallenge() {
            PasswordService.captchaChallenge({challengeId: vm.forgotData.challengeId}).then(function (data) {
                vm.forgotData.challengeId = data.challengeId;
                vm.forgotData.captcha = data.captcha;
            });
        }

        function openPopup() {
            $uibModal.open({
                animation: true,
                templateUrl: 'loginApp/sms-modal.html',
                controller: 'SmsModalController',
                controllerAs: 'vm'
            });
        }

        function getSubmitLabel() {
            if (vm.insertPinState) {
                return $filter('translate')('global.controls.continue');
            } else {
                return $filter('translate')('login.login');
            }
        }

        function goBackFromPinState() {
            vm.insertPinState = false;
            vm.loginData.pinCode = '';
        }

        function setupForgotPasswordState() {
            vm.resetError = false;
            vm.resetSuccess = false;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$scope', 'LoginLanguageService', 'PasswordService', '$stateParams'];

    function PasswordResetController($scope, LoginLanguageService, PasswordService, $stateParams) {
        var vm = this;

        vm.resetForm = 'resetForm';
        vm.confirmedPassword = '';
        vm.resetSuccess = false;
        vm.resetError = false;
        vm.tokenExpired = false;
        vm.resetData = {
            token: $stateParams.token,
            newPassword: ''
        };
        vm.validateToken = validateToken;
        vm.resetPassword = resetPassword;

        LoginLanguageService.getSupportedLanguages().then(function () {
            LoginLanguageService.determineLanguage().then(validateToken);
        }, function () {
            validateToken();
        });

        function resetPassword() {
            vm.resetData.language = LoginLanguageService.language;
            PasswordService.resetPassword(vm.resetData).then(function () {
                vm.resetError = false;
                vm.resetSuccess = true;
            }, function (error) {
                vm.resetError = true;
                vm.resetSuccess = false;
                if (!!error.data.globalErrors && error.data.globalErrors.length > 0) {
                    vm.errorCode = error.data.globalErrors[0].code;
                    vm.tokenExpired = (vm.errorCode === 'password-reset.tokenExpired');
                } else {
                    vm.errorCode = 'login.resetFail';
                }
            });
        }

        function validateToken() {
            var data = {
                token: vm.resetData.token
            };
            PasswordService.validateToken(data).then(function () {

            }, function () {
                vm.tokenExpired = true;
            });
        }

    }
})();

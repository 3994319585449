(function () {
    'use strict';

    angular
        .module('cockpit2Shared', [
            'ngResource',
            'pascalprecht.translate',
            'ngCookies',
            'ui.bootstrap'
        ]);
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Shared')
        .directive('xuraButton', xuraButton);

    function xuraButton() {
        return {
            restrict: 'E',
            templateUrl: 'shared/directives/button/xura-button.html',
            replace: true,
            compile: function (tElement, tAttrs) {
                var prefix = '';
                if (tAttrs.domainStyles === 'true') {
                    prefix = 'domain-';
                }
                if (tAttrs.modifier === 'secondary') {
                    tElement.addClass('secondary-button color-primary border-primary hover-color-secondary hover-border-secondary' +
                        ' hover-background-primary');
                } else if (tAttrs.modifier === 'text-only') {
                    tElement.addClass('secondary-button ' + prefix + 'color-primary');
                } else {
                    tElement.addClass('primary-button background-primary color-secondary');
                }
                tElement.removeAttr('modifier');
                if (tAttrs.icon) {
                    tElement.find('i').addClass('fa-' + tAttrs.icon);
                    tElement.removeAttr('icon');
                } else {
                    tElement.find('i').remove();
                }
                tElement.append('{{' + tAttrs.label + '}}');
                tElement.removeAttr('label');

                return function () {
                };
            }
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .factory('ImprintService', ImprintService);

    ImprintService.$inject = ['$http'];

    function ImprintService($http) {

        var service = {
            getImprintFile: getImprintFile
        };

        return service;

        function getImprintFile() {
            return $http.get('api/style/imprintFile/').then(function(response) {
                return response.data;
            });
        }
    }
})();


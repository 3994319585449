(function () {
    'use strict';

    angular
        .module('cockpit2Login')
        .controller('MaintenanceController', MaintenanceController);

    MaintenanceController.$inject = ['MaintenanceService', '$state'];

    function MaintenanceController(MaintenanceService, $state) {
        var vm = this;

        MaintenanceService.getMaintenanceSettings().then(function (result) {
            vm.labels = result.data;
            if (true !== result.data.maintenanceMode) {
                $state.go('login.main');
            }
        });
    }
})();
